<template>
  <breadcrumb-page :list="breadcrumb_links"></breadcrumb-page>

  <div id="maincontent_container">
    <submenu-support menu="rma"></submenu-support>

    <!-- page content on the right -->
    <div class="submenu-content">
        
          <p class="lead">Submit Online RMA</p>
          <div class="rmaRequest">
            <div v-html="items[0]" class="paragraph"></div>
            <i class="text-warning"
              >(Please note that your RMA account is different from your Ma Labs
              Web Access Account.)</i
            >
          </div>
          <br />
          <p>
            <a
              target="_blank"
              class="blue_link"
              :href="'/'+ items[1]"
              ><b>Review</b>&nbsp;
              <img :src="items[4]" />
            </a>
            &nbsp; &nbsp; <b>Limited Warranty &amp; RMA Service Policy</b>
          </p>
          <br />
          <p>
            <a
              target="_blank"
              class="blue_link"
              :href="'/'+ items[3]"
              ><b>Review</b>&nbsp;
              <img :src="items[4]" />
            </a>
            &nbsp; &nbsp; <b>Ma Labs Complete Server System RMA Policy and Procedure</b>
          </p>
          <br />
          <p class="lead">Submit Paper-based RMA</p>
          <p>
            <a
              target="_blank"
              class="blue_link"
              :href="'/' + items[2]"
              ><b>RMA Form (Excel File)</b></a
            >
          </p>
          <p>
            Northern California Service Center - San Jose, CA <br />
            2075 N . Capitol Avenue, San Jose, CA 95132 <br />
            Tel No:408-941-0808 Press 7 and then Press 1 <br />
            Fax No:408-941-0949<br />
            Email:
            <a class="blue_link" :href="items[2]">rma@malabs.com</a><br />
            <br />
            Southern California Service Center - City of Industry, CA <br />
            18725 San Jose Avenue,City of Industry,CA 91748<br />
            Tel No: 626-820-8988 Ext 145<br />
            Email:
            <a class="blue_link" href="mailto:rma@malabs.com">rma@malabs.com</a
            ><br />
          </p>
        
    </div>

  </div>
</template>

<script>
import SubmenuSupport from "../../components/nav/SubmenuSupport.vue";
import BreadcrumbPage from "../../components/BreadcrumbPage.vue";
import { getAPI } from "../../utils/axios-api";
import { ref } from "vue";

export default {
  name: "RMARequest",
  components: { SubmenuSupport, BreadcrumbPage },
  setup() {
    const RMARequest = ref("");
    const items = ref([]);
    const error = ref(null);

    //breadcrumb
    const breadcrumb_links = ref([])
    breadcrumb_links.value.push({text: "Support", link: ""})
    breadcrumb_links.value.push({text: "RMA Request", link: "RMARequest"})

    let url = "content/pages/57";
    getAPI
      .get(url)
      .then((response) => {
        if (response.status === 200) {
          RMARequest.value = response.data.body;         
          for (let index in RMARequest.value) {
            if(RMARequest.value[index].type == "image"){
              items.value.push(RMARequest.value[index].value.thumbnail.src)
            }else{
              items.value.push(RMARequest.value[index].value)
            }
              
          }
         
        }
      })
      .catch((err) => {
        error.value = "No page Found";
      });
    return { items, error, breadcrumb_links };
  },
};
</script>

<style scoped>
.lead {
  font-size: 26px;
  font-family: "Roboto";
  font-weight: 600;
  color: #555555;
}
body {
  min-width: 300px;
  color: #555555;
  font-family: Segoe, Arial;
  font-size: 16px;
}
.submenu-content a {
  color: #1ed2e9;
}
div >>> .rmaRequest a {
  color: #1ed2e9;
}
.alert .alert-link,
.close,
b,
optgroup,
strong {
  font-weight: 700;
}
</style>
